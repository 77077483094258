import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ContainerRow, ContainerColumn } from '@components/styled';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import TraderBadge from '@shared/ui/badges/TraderBadge';
import { getProfileDisplayName, getProfileUsername } from '../../lib';
import Nickname from '../ProfileNickname';
import ProfileSocials from '../ProfileSocials';
import ProfileDisplayName from '../ProfileDisplayName';
import Location from '../ProfileLocation';
import ProfileDescription from '../ProfileDescription';
import JoinDate from '../ProfileJoinDate';
import ProfileAvatarWrapper from '../ProfileAvatarWrapper';
const ProfilePreviewContainer = styled(ContainerRow)(() => ({
    height: 'auto',
}));
const ProfilePreviewInner = styled(ContainerRow)(props => ({
    gap: `0 ${props.theme.spacing_sizes.xs * 2.75}px`,
}));
const InfoContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const UsernameContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.s}px`,
}));
const LocationJoinDate = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    lineHeight: `${props.theme.spacing_sizes.l}px`,
    gap: props.theme.spacing_sizes.l,
}));
const DisplayNameContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
    alignItems: 'center',
}));
const ActionButtonsContainer = styled.div(() => ({
    textWrap: 'nowrap',
}));
const ProfilePreview = ({ profile, className = undefined, actionBtns = undefined, }) => {
    var _a;
    const nickname = getProfileUsername(profile);
    const name = getProfileDisplayName(profile);
    return (_jsxs(ProfilePreviewContainer, { className: className, children: [_jsxs(ProfilePreviewInner, { children: [_jsx(ProfileAvatarWrapper, { size: '128px', children: _jsx(AvatarImage, { publicName: profile === null || profile === void 0 ? void 0 : profile.displayName, imageUrl: (_a = profile === null || profile === void 0 ? void 0 : profile.profilePicture) === null || _a === void 0 ? void 0 : _a.avatar, size: 'medium' }) }), _jsxs(InfoContainer, { children: [_jsxs(UsernameContainer, { children: [_jsxs(DisplayNameContainer, { children: [_jsx(ProfileDisplayName, { children: name }), profile.type === 'TRADER' && (_jsxs(_Fragment, { children: [_jsx(VerificationIcon, { verified: Boolean(profile.isVerifiedTrader), size: 24 }), _jsx(TraderBadge, { size: 'small', textSize: 12, textHeight: '18px' })] }))] }), _jsx(ProfileSocials, { contacts: profile.contacts })] }), !!(profile === null || profile === void 0 ? void 0 : profile.displayName) && (_jsx(Nickname, { children: nickname })), _jsxs(LocationJoinDate, { children: [profile.location && _jsx(Location, { location: profile.location }), _jsx(JoinDate, { creationDate: profile.createdAt })] }), _jsx(ProfileDescription, { profileId: profile.id, text: profile.description })] })] }), actionBtns && (_jsx(ActionButtonsContainer, { children: actionBtns }))] }));
};
export default ProfilePreview;
